/*
*  Frappe Active Users © 2023
*  Author:  Ameen Ahmed
*  Company: Level Up Marketing & Software Development Services
*  Licence: Please refer to LICENSE file
*/
//Author:Gopi Kathiriya
//Date:23-3-2024
frappe.provide('frappe._active_user');
frappe.provide('frappe.dom');

class ActiveUsers {
    constructor() {
        var me = this;
        $(document).ready(function () {
            frappe.call({
                method: "sigzenerp.active_user.doctype.sigzen_active_users_settings.sigzen_active_users_settings.display_users",
                callback: function (r) {
                    if (r.message === true) {
                        $(".nav-link.active-users-navbar-icon.text-muted").show();
                    } else {
                        setTimeout(function () {
                            $(".nav-link.active-users-navbar-icon.text-muted").hide();
                        }, 100);
                    }
                    if (frappe.desk == null) {
                        frappe.throw(__('Active Users plugin cannot be used outside Desk.'));
                        return;
                    }

                    me.is_online = frappe.is_online ? frappe.is_online() : false;
                    me.on_online = null;
                    me.on_offline = null;

                    $(window).on('online', function () {
                        me.is_online = true;
                        me.on_online && me.on_online.call(me);
                        me.on_online = null;
                    });

                    $(window).on('offline', function () {
                        me.is_online = false;
                        me.on_offline && me.on_offline.call(me);
                        me.on_offline = null;
                    });

                    me.settings = {};
                    me.data = [];

                    me.setup();
                }
            });
        });
    }

    destroy() {
        this.clear_sync();
        if (this.$loading) this.$loading.hide();
        if (this.$reload) this.$reload.off('click').hide();
        if (this.$app) this.$app.remove();
        this.data = this._on_online = this._on_offline = this._syncing = null;
        this.$app = this.$body = this.$loading = this.$footer = this.$reload = null;
    }

    error(msg, args) {
        this.destroy();
        frappe.throw(__(msg, args));
    }

    request(method, callback, type) {
        var me = this;
        return new Promise(function(resolve, reject) {
            let data = {
                method: 'sigzenerp.api.' + method,
                'async': true,
                freeze: false,
                callback: function(res) {
                    if (res && $.isPlainObject(res)) res = res.message || res;
                    if (!$.isPlainObject(res)) {
                        me.error('Active Users plugin received invalid ' + type + '.');
                        reject();
                        return;
                    }
                    if (res.error) {
                        me.error(res.message);
                        reject();
                        return;
                    }
                    let val = callback && callback.call(me, res);
                    resolve(val || res);
                }
            };
            try {
                frappe.call(data);
            } catch(e) {
                (console.error || console.log)('[Active Users]', e);
                this.error('An error has occurred while sending a request.');
                reject();
            }
        });
    }

    setup() {
        if (!this.is_online) {
            this.on_online = this.setup;
            return;
        }
        var me = this;
        this.sync_settings()
        .then(function() {
            if (!me.settings.enabled) { 
                return;
            }
            me.setup_display();
            me.sync_reload();
        });
    }

    sync_settings() {
        return this.request(
            'get_settings',
            function(res) {
                res.enabled = cint(res.enabled);
                res.refresh_interval = cint(res.refresh_interval) * 60000;
                res.allow_manual_refresh = cint(res.allow_manual_refresh);
                this.settings = res;
            },
            'settings'
        );
    }

    setup_display() {
        let title = __('Active Users');
        this.$app = $(`
            <li class="nav-item dropdown dropdown-notifications dropdown-mobile active-users-navbar-item" title="${title}">
                <a class="nav-link active-users-navbar-icon text-muted"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-persist="true"
                    href="#" onclick="return false;">
                    <span class="fa fa-user fa-lg fa-fw" style="color: black;"></span>
                </a>
                <div class="dropdown-menu active-users-list" role="menu">
                    <div class="fluid-container">
                        <div class="row">
                            <div class="col active-users-list-header">${title}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col active-users-list-body">
                            <div class="active-users-list-loading">
                                <div class="active-users-list-loading-box"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col active-users-list-footer">
                            <div class="row">
                                <div class="col active-users-footer-text"></div>
                                <div class="col-auto active-users-footer-icon">
                                    <a href="#" class="active-users-footer-reload">
                                        <span class="fa fa-refresh fa-md fa-fw"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        `);
        
        $('header.navbar > .container > .navbar-collapse > ul.navbar-nav').prepend(this.$app.get(0));
        
        this.$body = this.$app.find('.active-users-list-body').first();
        this.$loading = this.$body.find('.active-users-list-loading').first().hide();
        this.$footer = this.$app.find('.active-users-footer-text').first();
        this.$reload = this.$app.find('.active-users-footer-reload').first();
    
        this.setup_manual_sync();
    
        // Prevent hiding dropdown when clicking inside the menu
        this.$app.find('.dropdown-menu').on('click', function(e) {
            e.stopPropagation();
        });
    
        // Hide dropdown when clicking outside
        $(document).on('click', function(e) {
            if (!$(e.target).closest('.active-users-navbar-item').length) {
                $('.dropdown-menu').removeClass('show');
            }
        });
    
        // Toggle the dropdown on clicking the nav link
        this.$app.find('.nav-link').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            var $dropdownMenu = $(this).next('.dropdown-menu');
            if ($dropdownMenu.hasClass('show')) {
                $dropdownMenu.removeClass('show');
            } else {
                $('.dropdown-menu').removeClass('show'); // Hide other open dropdowns
                $dropdownMenu.addClass('show');
            }
        });
    }

    setup_manual_sync() {
        var me = this;
        this.$reload.show().off('click').on('click', function(e) {
            e.preventDefault();
            // Trigger the reload functionality without hiding the button
            if (!me._syncing) me.sync_reload();
        });
    }

    sync_reload() {
        if (!this.is_online) return;
        this.clear_sync();
        var me = this;
        Promise.resolve()
            .then(function() { me.sync_data(); });
    }

    clear_sync() {
        if (this.sync_timer) {
            window.clearInterval(this.sync_timer);
            this.sync_timer = null;
        }
    }

    sync_data() {
        this._syncing = true;
        if (this.data.length) {
            this.$footer.html('');
            this.$body.empty();
        }
        this.$loading.show();
        this.request(
            'get_users',
            function(res) {
                this.data = res.users && Array.isArray(res.users) ? res.users : [];
                this.$loading.hide();
                this.update_list();
                this._syncing = null;
            },
            'users list'
        );
    }

    update_settings() {
        if (!this.is_online) {
            this.on_online = this.update_settings;
            return;
        }
        var me = this;
        this.sync_settings()
        .then(function() {
            if (!me.settings.enabled) {
                me.destroy();
                return;
            }
            me.setup_manual_sync();
            me.sync_reload();
        });
    }

    update_list() {
        var me = this;
        var currentUser;
    
        frappe.call({
            method: "frappe.client.get_value",
            args: {
                doctype: "User",
                filters: { name: frappe.session.user },
                fieldname: "full_name"
            },
            callback: function(response) {
                currentUser = response.message.full_name;
    
                frappe.call({
                    method: "sigzenerp.active_user.doctype.sigzen_active_users_settings.sigzen_active_users_settings.active",
                    callback: function (r) {

                        var names = r.message;
                
                        names = names.filter(function (name) {
                            return name.toLowerCase() !== 'administrator' && name.toLowerCase() !== 'support' && name.toLowerCase() !== currentUser.toLowerCase();
                        });
                        
    
                        me.$body.empty();
    
                        names.forEach(function (name) {
                            let avatar = frappe.get_avatar(null, name),
                                item = $(`
                                    <div class="row active-users-list-item">
                                        <div class="col-auto active-users-item-avatar">${avatar}</div>
                                        <div class="col active-users-item-name">${name}</div>
                                    </div>
                                `);
    
                            me.$body.append(item);
                        });
    
                        me.$footer.html(__('Total') + ': ' + names.length);
                    }
                });
            }
        });
    }
}    
frappe._active_user.init = function() {
    if (frappe._active_user._init) frappe._active_user._init.destory();
    if (frappe.desk == null) return;
    frappe._active_user._init = new ActiveUsers();
};

$(document).ready(function() {
    frappe._active_user.init();
});
